import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/creative-studio.scss";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import drishto_logo from "./styles/imgs/Drishto.png";
import drisho_banner from "./styles/imgs/Drishto-banner.png";
import drishto from "./styles/imgs/Dashboard.png";
import airlyft_logo from "./styles/imgs/Airlyft.png";
import airlyft_banner from "./styles/imgs/Airlift-banner.png";
import airlyft_dashboard from "./styles/imgs/Airlyft-dashboard.png";

import { useEffect, useRef, useState } from "react";

function App() {
  const [navBackground, setNavBackground] = useState(false);
  const [navChildBackground, setNavChildBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="home">
      {/* data-spy="scroll" data-target=".navbar" data-offset="40"  */}
      {/* class="navbar navbar-expand-lg navbar-light bg-light" */}
      <nav
        class="navbar custom-navbar navbar-expand-lg navbar-light affix-top"
        data-spy="scroll"
        style={{
          transition: "1s ease",
          backgroundColor: navBackground ? "#fff" : "transparent",
          borderBottom: navBackground && "1px solid #eaf0fc",
        }}
      >
        <div
          class="container nav-child"
          style={{
            background: navChildBackground && "#fff",
          }}
        >
          <img src="imgs/samrish_logo.png" alt="logo" class="img-logo"></img>
          <button
            class="navbar-toggler"
            onClick={() => setNavChildBackground(!navChildBackground)}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="#home">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about">
                  About Us
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#service">
                  Services
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#product1">
                  Products
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <header class="header">
        <div class="overlay"></div>
        <Swiper
          // spaceBetween={30}
          centeredSlides={true}
          loop={true}
          speed={6000}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div class="banner-container">
              <div class="container banner-info-container">
                <div class="banner-logo-container">
                  <div class="banner-logo">
                    <img src={drishto_logo} alt={"drishto-logo"} />
                  </div>
                  <div class="banner-info">
                    <p>
                      Drishto revolutionizes trip planning with versatile tools for diverse business needs, 
                      optimizing transportation costs and efficiency. 
                    </p>
                    <p>
                      It manages vendor contracts, streamlines 
                      invoicing with ad-hoc trip approvals, and ensures real-time tracking for seamless operations 
                      and timely deliveries.
                    </p>
                  </div>
                  <div class="buttons banner-buttons">
                    <a
                      href="#product1"
                      class="btn btn-outline-primary rounded w-lg btn-lg my-1 mr-4 banner-button"
                    >
                      See Details
                    </a>
                  </div>
                </div>
                <div class="banner">
                  <img src={drisho_banner} alt={"drishto-banner"} />
                </div>
              </div>

              <div class="container banner-mob-info-container">
                <div class="banner-mob-container">
                  <div class="banner-mob-info">
                    <div class="banner">
                      <img src={drisho_banner} alt={"drishto-banner"} />
                    </div>
                    <div class="banner-logo">
                      <img src={drishto_logo} alt={"drishto-logo"} />
                    </div>
                  </div>
                  <div class="banner-mob-desc">
                    <p>
                      Drishto revolutionizes trip planning with versatile tools for diverse business needs, 
                      optimizing transportation costs and efficiency. 
                    </p>
                    <p>
                      It manages vendor contracts, streamlines 
                      invoicing with ad-hoc trip approvals, and ensures real-time tracking for seamless operations 
                      and timely deliveries.
                    </p>
                  </div>
                  <div class="buttons banner-mob-buttons">
                    <a
                      href="#product1"
                      class="btn btn-outline-primary rounded w-lg btn-lg my-1 banner-mob-button"
                    >
                      See Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div class="banner-container">
              <div class="container banner-info-container">
                <div class="banner-logo-container">
                  <div class="banner-logo">
                    <img src={airlyft_logo} alt={"airlyft-logo"} />
                  </div>
                  <div class="banner-info">
                    <p>
                      Airlyft enables real-time airfreight monitoring using C-note numbers, tracking uplift 
                      and unload statuses. 
                    </p>
                    <p>
                      Its API integration streamlines communication with external systems, 
                      offering a user-friendly interface for efficient shipment management and providing notifications 
                      for key events, enhancing overall logistics visibility.
                    </p>
                  </div>
                  <div class="buttons banner-buttons">
                    <a
                      href="#product2"
                      class="btn btn-outline-primary rounded w-lg btn-lg my-1 mr-4 banner-button"
                    >
                      See Details
                    </a>
                  </div>
                </div>
                <div class="banner">
                  <img src={airlyft_banner} alt={"airlyft-banner"} />
                </div>
              </div>

              <div class="container banner-mob-info-container">
                <div class="banner-mob-container">
                  <div class="banner-mob-info">
                    <div class="banner">
                      <img src={airlyft_banner} alt={"airlyft-banner"} />
                    </div>
                    <div class="banner-logo">
                      <img src={airlyft_logo} alt={"airlyft-logo"} />
                    </div>
                  </div>
                  <div class="banner-mob-desc">
                    <p>
                      Airlyft enables real-time airfreight monitoring using C-note numbers, tracking uplift 
                      and unload statuses. 
                    </p>
                    <p>
                      Its API integration streamlines communication with external systems, 
                      offering a user-friendly interface for efficient shipment management and providing notifications 
                      for key events, enhancing overall logistics visibility.
                    </p>
                  </div>
                  <div class="buttons banner-mob-buttons">
                    <a
                      href="#product2"
                      class="btn btn-outline-primary rounded w-lg btn-lg my-1 banner-mob-button"
                    >
                      See Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div class="intro-banner">
              <h6 class="subtitle">Be part of our journey</h6>
              <h1 class="title">We Are Hiring...</h1>
              <div class="buttons-container">
                {/* <a
                  href="#service"
                  class="btn btn-primary rounded w-lg btn-lg my-1 mr-sm-2 button"
                >
                  Our Services
                </a> */}
                <a
                  href="#contact"
                  class="btn btn-outline-primary rounded w-lg btn-lg my-1 button"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </header>
      {/* <div class="box text-center">
        <div class="box-item">
          <i class="ti-vector"></i>
          <h6 class="box-title">Technology</h6>
          <p>
            We use technology as a tool to build solutions which to run
            businesses with efficency in cost effective ways
          </p>
        </div>
        <div class="box-item">
          <i class="ti-filter"></i>
          <h6 class="box-title">Innovation</h6>
          <p>
            We believe in pushing our limits to bring the best solution to
            reality.
          </p>
        </div>
        <div class="box-item">
          <i class="ti-mobile"></i>
          <h6 class="box-title">Business</h6>
          <p>We believe in generating values from the work we do.</p>
        </div>
      </div> */}

      <section id="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-5 col-lg-4">
              <img
                src="imgs/about.jpg"
                alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, Creative studio Landing page"
                class="w-100 img-thumbnail mb-3"
              ></img>
            </div>
            <div class="col-md-7 col-lg-8">
              <h6 class="section-subtitle mb-0">What we aim?</h6>
              <h6 class="section-title mb-3">Our Vision</h6>
              <p>
                As a technology startup, our vision is to revolutionize the transportation 
                and logistics industry by offering a comprehensive suite of innovative solutions. 
                We empower businesses with dynamic trip planning tools that cater to diverse scenarios and needs. 
              </p>
              <p>
                Our focus lies in enhancing trip management by reducing costs, ensuring timely transportation of goods, 
                and enabling proactive decision-making.
              </p>
              <p>
                We streamline vendor contract management, offering efficient operations, 
                cost management, and compliance tools. Our digital invoicing solutions, tailored 
                for both contracted and ad-hoc trips, ensure accuracy and transparency.
              </p>
              <p>
                Additionally, our real-time tracking services and air cargo 
                monitoring integrations bring operational visibility and efficiency to the forefront, 
                redefining how businesses manage their logistics seamlessly and effectively.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="service">
        <div class="container">
          <h6 class="section-subtitle text-center">What we offer?</h6>
          <h5 class="section-title text-center mb-6">Our Services</h5>
          <div class="row">
            <div class="col-sm-4">
              <div class="card mb-4">
                <div class="card-body height">
                  <h2 class="mb-4">
                    <i class="ti-map-alt text-primary"></i>
                  </h2>
                  <h6 class="card-title">Trip Planning</h6>
                  <p>
                    This solution provides tools to create trip plans of future
                    trips in variety of differnt ways and gives flexibility to
                    cater different business need multitude of scenarios.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card mb-4">
                <div class="card-body height">
                  <h2 class="mb-4">
                    <i class="ti-truck text-primary"></i>
                  </h2>
                  <h6 class="card-title">Trip Management</h6>
                  <p>
                    To meet customer demands, reduce transportation costs, and
                    improve overall efficiency. Bring proactive decision making
                    and operational coordination to ensure that goods are
                    transported in a timely and cost effective manner.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card mb-4">
                <div class="card-body height">
                  <h2 class="mb-4">
                    <i class="ti-clipboard text-primary"></i>
                  </h2>
                  <h6 class="card-title">Vendor Contract Management</h6>
                  <p>
                    Developing, enacting, and supervising agreements with
                    external vendors offering transportation services.
                    Facilitating efficient and seamless operations, managing
                    costs, and offering tools for compliance checks to
                    streamline the billing process.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card mb-4">
                <div class="card-body height">
                  <h2 class="mb-4">
                    <i class="ti-receipt text-primary"></i>
                  </h2>
                  <h6 class="card-title">Invoicing</h6>
                  <p>
                    Our software provides a digital solution for generating
                    invoices for vendors, whether for contracted or ad-hoc
                    trips. An approval process is included specifically for
                    ad-hoc trips.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card mb-4">
                <div class="card-body height">
                  <h2 class="mb-4">
                    <i class="ti-target text-primary"></i>
                  </h2>
                  <h6 class="card-title">Real-Time Tracking</h6>
                  <p>
                    Developing, enacting, and supervising agreements with
                    external vendors offering transportation services.
                    Facilitating efficient and seamless operations, managing
                    costs, and offering tools for compliance checks to
                    streamline the billing process.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card mb-4">
                <div class="card-body height">
                  <h2 class="mb-4">
                    <i class="ti-package text-primary"></i>
                  </h2>
                  <h6 class="card-title">Air Cargo Tracking</h6>
                  <p>
                    Airfreight monitoring utilizing C-note numbers, tracking
                    uplift and unload statuses, and integration with APIs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="product1">
        <div class="container">
          <h5 class="section-title text-center mb-6">Our Products</h5>
          <div class="row">
            <div class="product-section">
              <img src={drishto_logo} alt={"drishto-logo"} />
              <div class="product-container">
                <div class="product-info">
                  <span class="product-header">Salient Features</span>
                  <ul>
                    <li>Dashboard for key performance indicator.</li>
                    <li>Route Planning.</li>
                    <li>Vendor on-boarding.</li>
                    <li>Vendor contract management.</li>
                    <li>Planning and scheduling standard trips.</li>
                    <li>Invoicing of approval or contractual trips.</li>
                    <li>Automated contract compliance checking.</li>
                    <li>
                      Approval process for ad-hoc trips and trips violating
                      contracts.
                    </li>
                    <li>Fuel-surcharge calculation in invoicing.</li>
                    <li>Fine-grained access control and role based access.</li>
                    <li>Document sharing and record keeping.</li>
                    <li>Auto check-ins/check-outs.</li>
                    <li>Integration APIs.</li>
                  </ul>
                </div>

                <div class="production-image-container">
                  <img src={drishto} alt={"drishto-banner"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="product2">
        <div class="container">
          <div class="row">
            <div class="product-section">
              <img src={airlyft_logo} alt={"airlyft-logo"} />
              <div class="product-container">
                <div class="product-info">
                  <span class="product-header">Salient Features</span>
                  <ul>
                    <li>Dashboard for key performance indicators.</li>
                    <li>Customer Note(C-Note) creation.</li>
                    <li>Shipment tracking against C-Note.</li>
                    <li>Vendor on-boarding.</li>
                    <li>Flight master.</li>
                    <li>Place master.</li>
                    <li>Uplift/unloaded tracking.</li>
                    <li>X-Ray scanning staus recording.</li>
                    <li>Fuel-surcharge calculation in invoicing.</li>
                    <li>Fine-grained access control and role based access.</li>
                    <li>Integration APIs.</li>
                  </ul>
                </div>

                  <div class="production-image-container">
                    <img src={airlyft_dashboard} alt={"airlyft-banner"}/>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        {/* <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <iframe
              width="100%"
              height="475"
              src="https://www.youtube.com/embed/TP4THzsAa3M"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div> */}
      </div>

      <section id="contact">
        <div class="container">
          <div class="contact-card">
            <div class="infos">
              <h6 class="section-subtitle">How to reach out?</h6>
              <h6 class="section-title mb-4">Contact Us</h6>
              <div class="contact-details">
                <div class="item">
                  <i class="ti-location-pin"></i>
                  <div class="">
                    <h5>Address</h5>
                    <p>
                      {" "}
                      D 59/2 Swaroop Nagar, Delhi,
                      <br /> Pincode 110042
                    </p>
                  </div>
                </div>
                <div class="item">
                  <i class="ti-mobile"></i>
                  <div>
                    <h5>Phone Number</h5>
                    <p>+91-9870325594</p>
                  </div>
                </div>
                <div class="item">
                  <i class="ti-email"></i>
                  <div class="mb-0">
                    <h5>Email Address</h5>
                    <p>info@samrish.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer  */}
      <section class="has-bg-img py-0">
        <div class="container">
          <div class="footer">
            <div class="row">
              <div class="footer-content">
                <ul class="menu">
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#service">Services</a>
                  </li>
                  <li>
                    <a href="#about">About Us</a>
                  </li>
                </ul>
                <ul class="menu">
                  <li class="icon">
                    <a href="https://www.linkedin.com/company/samrish-technologies/">
                      <i class="ti-linkedin"></i>
                    </a>
                  </li>
                  <li class="icon">
                    <a href="https://www.google.co.in/maps/place/Samrish+Technologies+Pvt+Ltd/@28.754399,77.1508089,21z/data=!4m14!1m7!3m6!1s0x390d0112f985b7ad:0x28b013e614288e58!2sSamrish+Technologies+Pvt+Ltd!8m2!3d28.7544207!4d77.150942!16s%2Fg%2F11l1n1fs3m!3m5!1s0x390d0112f985b7ad:0x28b013e614288e58!8m2!3d28.7544207!4d77.150942!16s%2Fg%2F11l1n1fs3m?entry=ttu">
                      <i class="ti-location-pin"></i>
                    </a>
                  </li>
                </ul>

                <p class="text-center">
                  Copyright © 2023 All Rights Reserved By Samrish Technologies Pvt. Ltd.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
